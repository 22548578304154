import {Injectable} from '@angular/core';
import {ProjectName} from "../../enums/project-name.enum";
import {map} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {EnvironmentsService} from "../environments.service";
import {JwtHelperService} from "@auth0/angular-jwt";
import {ResponseMessageModel} from "../../models/ResponseMessageModel";

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(private http: HttpClient, private env: EnvironmentsService, private jwtHelper: JwtHelperService) { }

  reloadCache(env: string, id: number) {
    return this.http.post(`${this.env.getKonpadBeEnvUrl(env, ProjectName.scoprilo)}v1/utils/reloadcache/reload`, {id},{headers: {Authorization: `Bearer ${this.jwtHelper.tokenGetter()}`}}).pipe(map((data) => data as ResponseMessageModel));
  }

  reloadCachePrereload(env: string) {
    return this.http.get(`${this.env.getKonpadBeEnvUrl(env, ProjectName.scoprilo)}v1/utils/reloadcache/prereloadhandshake`, {headers: {Authorization: `Bearer ${this.jwtHelper.tokenGetter()}`}}).pipe(map((data) => data as ResponseMessageModel));
  }
}
