import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ProjectName} from '../../enums/project-name.enum';
import {EnvironmentsService} from '../environments.service';
import {map} from 'rxjs/operators';

import {JwtHelperService} from '@auth0/angular-jwt';
import {ResponseMessageModel} from "../../models/ResponseMessageModel";

@Injectable({
  providedIn: 'root'
})
export class StatusService {

  constructor(private http: HttpClient, private env: EnvironmentsService, private jwtHelper: JwtHelperService) { }

  getHealth(env: string, project: ProjectName) {
    return this.http.get(`${this.env.getKonpadBeEnvUrl(env, project)}v1/status/checkhealth`, {headers: {Authorization: `Bearer ${this.jwtHelper.tokenGetter()}`}}).pipe(map((data) => data as ResponseMessageModel));
  }

  regenStatus(env: string, id: number) {
    return this.http.get(`${this.env.getKonpadBeEnvUrl(env, ProjectName.scoprilo)}v1/status/regenstatus/${id}`, {headers: {Authorization: `Bearer ${this.jwtHelper.tokenGetter()}`}}).pipe(map((data) => data as ResponseMessageModel));
  }
}
