import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {EnvironmentsService} from '../environments.service';
import {JwtHelperService} from '@auth0/angular-jwt';
import {ProjectName} from '../../enums/project-name.enum';
import {map, switchMap, takeUntil} from 'rxjs/operators';

import {UtilsService} from '../Utils/utils.service';
import {interval, Observable, Subject, Subscription} from 'rxjs';
import {StatusService} from '../Status/status.service';
import {CacheRegenStatus} from '../../enums/cache-regen-status.enum';
import {LocalNotifications} from '@ionic-native/local-notifications/ngx';
import {Platform, ToastController} from '@ionic/angular';
import {AlertBoxDispatcherService} from '../alert-box-dispatcher.service';
import {App} from '@capacitor/core';
import {BackgroundMode} from '@ionic-native/background-mode/ngx';

@Injectable({
  providedIn: 'root'
})
export class BackgroundReloadCacheService {

  private regen: Subscription;
  private stopRegenCheck: Subject<boolean> = new Subject();
  private isFirstStart = true;
  private reloadInProgress: {env: string}[] = [];

  constructor(private platform: Platform, private http: HttpClient, private env: EnvironmentsService,
              private jwtHelper: JwtHelperService, private utils: UtilsService, private status: StatusService,
              private localNotification: LocalNotifications, private toast: ToastController,
              private alert: AlertBoxDispatcherService,
              private backgroundMode: BackgroundMode) {
  }

  startReloading(env: string) {
    this.regen = this.utils.reloadCachePrereload(env).pipe(switchMap(s => {
      this.startCheck(env, s.data.id);
      this.enableBackgroundCheck();
      return this.utils.reloadCache(env, s.data.id);
    })).subscribe();
    //this.regen.unsubscribe();
  }

  async askReloading(env: string) {
    await this.alert.chooseBox(
      'Cache',
      'Vuoi ricaricare la cache?',
      'Si',
      () => {
        this.startReloading(env);
      },
      'No'
    );
  }

  getReloadProgress(env: string) {
    const id = this.reloadInProgress.findIndex(value => value.env === env);
    return id !== -1;
  }

  private startCheck(env: string, id: number) {
    this.reloadInProgress.push({env});
    if (this.isFirstStart) {
      interval(10 * 1000).pipe(switchMap(() => this.status.regenStatus(env, id)), takeUntil(this.stopRegenCheck)).subscribe(async r => {
        if (r.data === CacheRegenStatus.COMPLETED) {
          if (this.platform.is('mobile')) {
            this.localNotification.schedule({
              text: 'Il caricamento della cache è stato completato con successo',
            });
          } else {
            const toast = await this.toast.create({
              message: 'Il caricamento della cache è stato completato con successo',
              duration: 3000
            });
            await toast.present();
          }
          this.reloadInProgress.splice(this.reloadInProgress.findIndex(value => value.env === env), 1);
          console.log('ciao', this.reloadInProgress.findIndex(value => value.env === env), this.reloadInProgress.length);
          this.stopRegenCheck.next(true);
          this.backgroundMode.disable();

        } else if (r.data === CacheRegenStatus.FAILED) {
          if (this.platform.is('mobile')) {
            this.localNotification.schedule({
              text: 'Il caricamento della cache è fallito a causa di un errore',
            });
          } else {
            const toast = await this.toast.create({
              message: 'Il caricamento della cache è fallito a causa di un errore',
              duration: 3000
            });
            await toast.present();
          }
          this.reloadInProgress.splice(this.reloadInProgress.findIndex(value => value.env === env), 1);
          this.stopRegenCheck.next(true);
          this.backgroundMode.disable();
        }

      });
    } else {
      this.stopRegenCheck.next(false);
    }
  }

  private enableBackgroundCheck() {
    /*console.log('async 1');
    App.addListener('appStateChange', async ({isActive}) => {
      console.log('App state changed. Is active?', isActive);
      if (!isActive) {
        // The app has become inactive. We should check if we have some work left to do, and, if so,
        // execute a background task that will allow us to finish that work before the OS
        // suspends or terminates our app:

        const taskId = await BackgroundTask.beforeExit(async () => {
          console.log('async 2');
          this.startCheck(env);
        });

        this.stopRegenCheck.asObservable().subscribe(d => {
          BackgroundTask.finish({
            taskId,
          });
        });
      }
    });*/
    this.backgroundMode.overrideBackButton();

    this.backgroundMode.enable();
    this.backgroundMode.on('activate').subscribe(()=>{
      this.backgroundMode.moveToBackground();
    });
    this.backgroundMode.on('deactivate').subscribe(()=>{
          this.backgroundMode.moveToForeground();
    });

  }

}
